.about-objectives-wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.about-desc-wrapper {
  max-width: 500px;
}

.about-desc {
  margin-bottom: 40px;
  font-size: 15px;
  color: #666666;
  line-height: 25px;
  text-align: justify;
}

.about-us-image {
  width: 570px;
  height: fit-content;
  border-radius: 8px;
}

.about-list {
  margin-top: 20px;
}

.about-list-item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 8px;
  box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
  padding: 10px 15px;
  column-gap: 10px;
}

.about-list-item :last-child {
  margin-bottom: 0;
}

.about-list-item-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d3ae6e;
  color: #ffffff;
  border-radius: 50%;
  padding: 10px;
}

.about-list-item-text {
  font-size: 15px;
  color: #666666;
  line-height: 25px;
}

.about-list-item-icon {
  color: #ffffff;
}

@media (max-width: 1000px) {
  .about-objectives-wrapper {
    row-gap: 20px;
    flex-wrap: wrap-reverse;
  }
  .about-us-image {
    width: 100%;
  }

  .about-desc-wrapper {
    max-width: 100%;
  }
}
