body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.invalid {
  color: red;
  font-style: italic;
  margin-top: 5px;
}
