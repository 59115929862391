.dropzone {
  min-height: 86px;
  background: #fff;
  border: 2px dashed #e5e9f2;
  border-radius: 4px;
  min-height: 150px;
  padding: 20px;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dz-preview {
  display: inline-block;
  margin: 16px;
  min-height: 100px;
  position: relative;
  vertical-align: top;
}

.dz-preview.dz-image-preview {
  background: #fff;
}

.dz-image {
  border-radius: 20px;
  display: block;
  height: 120px;
  overflow: hidden;
  position: relative;
  width: 120px;
  z-index: 10;
}

.dropzone-image {
  height: 120px;
  width: 120px;
}

.delete-button-wrapper {
  position: absolute;
  bottom: 10px;
  right: 12px;
}
