.btn-wrapper {
  width: fit-content;
}

.outline {
  color: #662a30;
  background-color: #fff;
  border: none;
}

.button {
  display: flex;
  align-items: center;
  background-color: #662a30;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 8px 30px;
}

.button-text {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}

.button:hover {
  background-color: #d3ae6e;
}

.button > div > i,
.outline > div > i {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
}

.button-icon {
  width: 13px;
  margin-left: 5px;
}
