.strategy-area {
  padding: 30px 0;
}

.strategy-title {
  color: #444444;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;
}

.strategy-title::before {
  position: absolute;
  content: "";
  background: rgb(102, 42, 48);
  width: 50px;
  height: 3px;
  bottom: 0;
  left: 0;
}

.strategy-desc {
  margin-bottom: 40px;
  font-size: 15px;
  color: #666666;
  line-height: 28px;
}

.strategy-image-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.strategy-image {
  border-radius: 8px;
}

.strategy-years {
  color: #444444;
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .strategy-image {
    max-width: 700px;
  }
}

@media (max-width: 767px) {
  .strategy-area {
    padding: 20px 0;
  }

  .strategy-image {
    max-width: 390px;
  }
}
