.d-contents {
  display: contents;
}

.partner-link {
  font-size: 14px;
  color: #662a30;
  font-weight: 500;
}

.partner-link:hover {
  text-decoration: underline;
}
